'use client'
import { DEFAULT_LANGUAGE } from '@/constants'
import { Language } from '@/utils/language'
import { createContext } from 'react'

export const LanguageContext = createContext({ language: DEFAULT_LANGUAGE })

type Props = {
    children: JSX.Element
    lang: Language
}

export const LanguageProvider = ({ children, lang }: Props) => {
    return (
        <LanguageContext.Provider value={{ language: lang }}>
            {children}
        </LanguageContext.Provider>
    )
}
