'use client'
import React, { createContext, ReactNode, useState } from 'react'

export type DebugContextType = {
    isDebugMode: boolean
    toggleDebugMode?: () => void
}

export const DebugContext = createContext<DebugContextType>({
    isDebugMode: false,
})

type DebugContextComponentProps = {
    children: ReactNode
}

const DebugProvider = ({ children }: DebugContextComponentProps) => {
    const [isDebugMode, setDebugMode] = useState<boolean>(false)

    const toggleDebugMode = () => {
        setDebugMode((prevState) => !prevState)
    }

    return (
        <DebugContext.Provider value={{ isDebugMode, toggleDebugMode }}>
            {children}
        </DebugContext.Provider>
    )
}

export default DebugProvider
