import { Currency } from '@/utils/currency'
import { Language } from '@/utils/language'

export const LANGUAGE_STORAGE_KEY = 'web-lang'
export const CURRENCY_STORAGE_KEY = 'web-currency'

export const DEFAULT_COUNTRY = Number(
    process.env.NEXT_PUBLIC_ESHOP_DEFAULT_COUNTRY_ID || 46
)

export const DEFAULT_LANGUAGE = (process.env.DEFAULT_LANGUAGE ||
    'cs') as Language

export const DEFAULT_CURRENCY = (process.env.DEFAULT_CURRENCY ||
    'CZK') as Currency

export const BASKET_STORAGE_KEY = 'lach-basket'
export const BASKET_INVOICE_STORAGE_KEY = 'lach-basket-invoice'
